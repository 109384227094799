<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="position-title">{{$t('campaigns.contents')}}</b-card-title>
        <div>
         
          <b-dropdown
            :disabled="loader_short"
            no-caret
            right
            variant="transparent"
            class="chart-dropdown mr-1 mt-1"
            toggle-class="p-50"
            size="sm"
          >
          <template #button-content>
            <b-button :class="`button-full-width ${mediaFiltered.length > 0 ? '' : 'disabled'}`" variant="outline-primary" @click="iconShort = !iconShort">
            <b-spinner v-if="loader_short" small class="margin-loader" type="grow"></b-spinner> {{$t(`media_ig.${sort_selected.label}`)}}
            <b-icon :icon="iconShort ? 'chevron-down': 'chevron-up'" v-if="!loader_short"></b-icon> </b-button>
          </template>
       
            <b-dropdown-item
              v-for="sort in sorts"
              :key="sort.value"
              @click="changeSort(sort)"
            >
              <span @click="paginationContent(),iconShort = !iconShort">{{ $t(`media_ig.${sort.label}`) }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            :disabled="loader_day"
            no-caret
            right
            variant="transparent"
            class="chart-dropdown"
            toggle-class="p-50 mt-1"
            size="sm"
          >
          <template #button-content>
            <b-button :class="`button-full-width ${mediaFiltered.length > 0 ? '' : 'disabled'}`" variant="outline-primary" @click="iconDay = !iconDay">
            <b-spinner v-if="loader_day" small class="margin-loader" type="grow"></b-spinner>{{$t(`period_media_ig.${day_selected}`)}}
            <b-icon :icon="iconDay ? 'chevron-down': 'chevron-up'" v-if="!loader_day"></b-icon></b-button>
          </template>
            <b-dropdown-item
              v-for="day in last_days"
              :key="day.value"
              @click="changeDay(day)"
            >
             <span @click="paginationContent(),iconDay = !iconDay">{{ $t(`period_media_ig.${day.label}`) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-header>
    </b-card>

    <b-row class="match-height justify-content-md-center">
      <b-col
      class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ml-1 mr-1"
        v-for="(media, index) in mediaFiltered" :key="index"
      >
        <b-card
          no-body
          class="card hover-card card-image-content card-type-content"
        >
          <b-link :href="formatLink(media)" target="_blank">
            <b-img-lazy class="card-image-content" :src="media.basic.preview_url" @error.native="errorLoad(index)"/>
          </b-link>
          <div class="card-content-all">
            <div class="container-interactions">
              <b-img  class="icon-network-media" :src="`${utils.getImageNetworkColor('instagram')}`"/>
              <b-avatar class="avatar-style" size="5rem" :src="media.avatar"/>
              <span class="influencer-username"> {{ media.username }}
                <image-verified v-if="media.is_verified == true"/>
              </span> 
              <div class="text-center max-width-100">

                <p class="m-1 text-center text-md-left max-width-100">
                  {{ utils.shortText(number_words, media.basic.caption) }} ...
                  <b-link :href="formatLink(media)" target="_blank" class="text-link ml-1 cursor-pointer" v-if="media.basic.caption && media.basic.caption.split(' ').length > number_words">
                    <span :id="`view${index}`">{{$t('instagram.seeMore')}}</span> 
                  </b-link>
                  <b-tooltip :target="`view${index}`" variant="primary" :title="media.basic.caption" placement="bottom" custom-class="tooltip-contents-view" />
                </p>

                <span class="d-flex align-items-center justify-content-center">{{`${utils.getDatev2(new Date(media.basic.time_posted * 1000))}`}}</span>
              </div>
              <div class="col-12 row">
                <div class="mt-1 col-6">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-icon class="mr-1" icon="heart-fill"/>
                    <span>{{ `${media.metrics.likes_count ? utils.getFormat(media.metrics.likes_count) : 0}` }}</span>
                  </div>
                </div>
                <div class="mt-1 col-6">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-icon class="mr-1" icon="chat"/>
                    <span>{{ `${media.metrics.comments_count ? utils.getFormat(media.metrics.comments_count) : 0}` }}</span>
                  </div>
                </div>
                <div class="mt-1 col-6" v-if="media.metrics.video_views_count > 0">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-icon class="mr-1" icon="play-circle"/>
                    <span>{{ `${utils.getFormat(media.metrics.video_views_count)}` }}</span>
                  </div>
                </div>
                <div class="mt-1 col-6">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-icon class="mr-1" icon="bar-chart-line"/>
                    <span>{{ `${media.metrics.er ? utils.getFormat(media.metrics.er) : 0}` }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col class="no-results" v-if="mediaFiltered.length === 0">
        <h4 class="mt-3 text-center">
          {{$t('campaigns.noResults')}}
        </h4>
        <div class="no-results">
        <b-img :src="require('@/assets/images/coming-soon.svg')"></b-img>
        </div>
      </b-col>
    </b-row>
    <b-pagination
      class="d-flex justify-content-center"
      v-model="current_page"
      :total-rows="filter_contents.length"
      :perPage="per_page"
    >
    </b-pagination>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BImg, BImgLazy, BAvatar, BIcon, BLink,BPagination, BButton, BTooltip, BSpinner} from 'bootstrap-vue';
import utils from '@/libs/utils'
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  name: 'InstagramMedia',
  components: {
    ImageVerified,
    BSpinner,
    BTooltip,
    BButton,
    BPagination,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BImg,
    BImgLazy,
    BAvatar,
    BIcon,
    BLink,
  },
  props: {
    contents: {
      type: Array,
      default: () => []
    },
    headerData: {
      type: Object,
      required: true
    },
    perPage: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      backgroundDefault: require("@/assets/images/dashboard/imgDefault.png"),
      loader_short: false,
      loader_day: false,
      own_number_words: this.number_words,
      utils,
      current_page: 1,
      per_page: this.perPage,
      iconShort: true,
      iconDay: true,
      toggle_read_more: true,
      day_selected: 'all',
      last_days: [
        {
          label: 'all',
          value: 0
        },
        {
          label: 'last_30',
          value: 30
        },
        {
          label: 'last_90',
          value: 90
        },
        {
          label: 'last_180',
          value: 180
        },
      ],
      sort_selected: {
        label: 'most_recent',
        value: 'most_recent'
      },
      sorts: [
        {
          label: 'most_recent',
          value: 'most_recent'
        },
        {
          label: 'most_engaged',
          value: 'most_engaged'
        },
        {
          label: 'most_liked',
          value: 'most_liked'
        },
        {
          label: 'most_commented',
          value: 'most_commented'
        },
        {
          label: 'most_viewed',
          value: 'most_viewed'
        },
      ],
      filter_contents: this.contents,
      number_words: 5
    }
  },
  computed: {
    mediaFiltered () {
      const items = this.filter_contents
      return items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page)
    },
  },
  created() {
    this.sortContents('most_recent');
  },
  methods: {
    errorLoad(index) {
      this.mediaFiltered[index].basic.preview_url = this.backgroundDefault;
    },
    paginationContent() {
      this.current_page = 1
    },
    sortContents(sort) {
      if (sort === 'most_recent') { 
        this.filter_contents.sort((a, b) => {
          return b.basic.time_posted - a.basic.time_posted
        })
      } else if (sort === 'most_engaged') {
        this.filter_contents.sort((a, b) => {
          return b.metrics.er - a.metrics.er
        })
      } else if (sort === 'most_liked') {
        this.filter_contents.sort((a, b) => {
          return b.metrics.likes_count - a.metrics.likes_count
        })
      } else if (sort === 'most_commented') {
        this.filter_contents.sort((a, b) => {
          return b.metrics.comments_count - a.metrics.comments_count
        })
      } else if (sort === 'most_viewed') {
        this.filter_contents.sort((a, b) => {
          return b.metrics.video_views_count - a.metrics.video_views_count
        })
      } else {
        this.filter_contents.sort((a, b) => {
          return b.basic.time_posted - a.basic.time_posted
        })
      }
      this.loader_short = false
      setTimeout(() => {
        this.$vs.loading.close()
      }, 250);
    },
    formatLink(media) {
      return `https://www.instagram.com/p/${media.basic.code}/`
    },
    changeSort(sort) {
      this.$vs.loading()
      this.loader_short = true
      this.sort_selected = sort
      this.sortContents(sort.value)
    },
    changeDay(day) {
      this.$vs.loading()
      this.loader_day = true
      this.day_selected = day.label
      const date = new Date();

      if (day.value === 30) {
        date.setDate(date.getDate() - 30);
        this.filter_contents = this.contents.filter(media => {
          return (media.basic.time_posted * 1000) >= date.getTime()
        })
      } else if (day.value === 90) {
        date.setDate(date.getDate() - 90);
        this.filter_contents = this.contents.filter(media => {
          return (media.basic.time_posted * 1000) >= date.getTime()
        })
      } else if (day.value === 180) {
        date.setDate(date.getDate() - 180);
        this.filter_contents = this.contents.filter(media => {
          return (media.basic.time_posted * 1000) >= date.getTime()
        })
      } else {
        this.filter_contents = this.contents
      }
      this.loader_day = false
      this.sortContents(this.sort_selected.value)
    }
  }
}
</script>

<style>
.text-link{
  color: #00cfe8;
}
.text-link:hover{
  color: #00cfe8;
}
.text-link:focus{
  color: #00cfe8;
}
.margin-loader{
  margin-right: 3px;
}
.button-full-width{
  width: 175px !important;
}
.no-results{
  display: flex;
  height: 25%;
  justify-content: center;
}
.hover-card:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.card-image-content {
  width: 100%;
  object-fit: cover;
  height: 300px;
  border-radius: 1%;
}
.card-content-all {
  width: 100%;
  height: 290px;
}
.card-type-content {
 display: flex;
 height: 90%;
}
.icon-network-media {
  position: relative;
  width: 25px !important;
  height: 25px !important;
  left: 23px;
  bottom: -75px;
  z-index: 2;
}
.container-interactions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 3.3em;
}
.avatar-style {
  padding: 5px;
  background-color: light;
}
.influencer-username {
  margin-top: 1rem;
  font-weight: bold;
}
.max-width-100 {
  max-width: 100%;
}
.position-title{
  margin-right: 180px;
  margin-left: 7px;
}
@media (max-width: 930px) {
  .position-title{
    margin-top: -5px;
    padding: 0px 0px 50px 0px;
  }
}
</style>
